/** @jsx $jsx */
import { $jsx } from 'framework7';
function framework7Component(props, _ref) {
  var $f7 = _ref.$f7,
    $on = _ref.$on,
    $update = _ref.$update;
  return function ($ctx) {
      var $ = $ctx.$;
      var $h = $ctx.$h;
      var $root = $ctx.$root;
      var $f7 = $ctx.$f7;
      var $f7route = $ctx.$f7route;
      var $f7router = $ctx.$f7router;
      var $theme = $ctx.$theme;
      var $update = $ctx.$update;
      var $store = $ctx.$store;

      return $h`
<div class="page" data-name="about">
  <div class="navbar">
    <div class="navbar-bg"></div>
    <div class="navbar-inner sliding">
      <div class="left">
        <a href="#" class="link back">
          <i class="icon icon-back"></i>
          <span class="if-not-md">Back</span>
        </a>
      </div>
      <div class="title">About</div>
    </div>
  </div>
  <div class="page-content">
    <div class="block-title">About this app</div>
    <div class="block block-strong">
      <p>It has been created to manage car lend between users to Deleen Auto.</p>
    </div>
    <div class="block-footer">
      <p>© AppDigCab, 2023-2024 (v2.0)</p>
    </div>
  </div>
</div>
`
    }
    ;
}
framework7Component.id = '6d3cdfe8c2';
export default framework7Component;