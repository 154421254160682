import { createStore, getDevice } from 'framework7';
import { http } from '../api/config.js';
import { localForage } from '../api/encrypt.js';

const device = createStore({
  state: {
    deviceToken: '',
    deviceType: 0,
    deviceTokenEmissionDate: null,
    isTokenSaved: 0,
  },
  actions: {
//Mutations
    setIsTokenSaved({state}, isTokenSaved){
      state.isTokenSaved = isTokenSaved;
    },
    set_device_data({ state }, { deviceToken, deviceType, deviceTokenEmissionDate }){
      state.deviceToken = deviceToken;
      state.deviceType = deviceType;
      state.deviceTokenEmissionDate = deviceTokenEmissionDate;
    },
//End mutations
    async setDeviceData({ dispatch }, { deviceToken, deviceType, deviceTokenEmissionDate }){
      await localForage.setItem('deviceToken', deviceToken);
      await localForage.setItem('deviceType', deviceType);
      await localForage.setItem('deviceTokenEmissionDate', deviceTokenEmissionDate);
      dispatch('set_device_data', { deviceToken, deviceType, deviceTokenEmissionDate});
    },
    async getDeviceData({ dispatch }){
      const result = {
        deviceToken: await localForage.getItem('deviceToken'),
        deviceType: await localForage.getItem('deviceType'),
        deviceTokenEmissionDate: await localForage.getItem('deviceTokenEmissionDate'),
      }
      dispatch('set_device_data', result);
    },
    async clearDeviceData(){
      await localForage.deleteItem('deviceToken');
      await localForage.deleteItem('deviceType');
      await localForage.deleteItem('deviceTokenEmissionDate');
    },
    async checkTokenExpiration ({ dispatch }){
      let deviceTokenEmissionDate = await localForage.getItem('deviceTokenEmissionDate');
      if (deviceTokenEmissionDate != null){
        const emissionDate = new Date(deviceTokenEmissionDate);
        const todayDate = new Date(); //Current date
        const diffTime = Math.abs(todayDate - emissionDate);
        const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24)); 
        if (diffDays < 30){
          await dispatch('getDeviceData');
        }
        else{
          // await messaging.deleteToken(); || await messaging.refreshToken();
          // await dispatch('clearDeviceData');
        }
      }
    },
    async sendTokenToServer ({ dispatch }, currentToken){
      try{
        let response = [];
        let result = {};
        let deviceType = 1;

        deviceType = (getDevice().cordova && getDevice().android && getDevice().androidChrome) ? 2 : deviceType;

        const data = {
          'DeviceToken': currentToken,
          'DeviceTypeId': deviceType,
        }

        result = await http.post(`device/create`, data, { headers: {'content-type': 'application/x-www-form-urlencoded'} });
        if (result.status === 201){
          response = result.data;
          const obj = {
            deviceToken: currentToken,
            deviceType: deviceType,
            deviceTokenEmissionDate: response.DeviceTokenEmissionDate,
          }
          await dispatch('setDeviceData', obj);
          return response;
        }
        else{
          return result;
        }
      }
      catch (error) {
        //await clearDeviceData();
        throw new error;
      }
    },
    async isTokenSentToServer({ dispatch }) {
      let isSaved = await localForage.getItem('sentToServer');
      dispatch('setIsTokenSaved', isSaved);
      return isSaved === '1';
    },
    async setTokenSentToServer({ dispatch }, sent) {
      const toSave = sent ? '1' : '0';
      dispatch('setIsTokenSaved', toSave);
      await localForage.setItem('sentToServer', toSave);
    },
    async getData({ dispatch }){
      let response = [];
      let result = {};

      result = await http.get(`device/get`);
      if (result.status === 200){
        response = result.data;
        return response;
      }
      else{
        return result;
      }
    },

    async addToken({ dispatch }, data){
      let response = [];
      let result = {};

      result = await http.post(`device/add`, data, { headers: {'content-type': 'application/x-www-form-urlencoded'} });
      if (result.status === 201){
        response = result.data;
        return response;
      }
      else{
        return result;
      }
    },

    async updateToken({ dispatch }, data){
      let response = [];
      let result = {};

      result = await http.post(`device/modify`, data, { headers: {'content-type': 'application/x-www-form-urlencoded'} });
      if (result.status === 200){
        response = result.data;
        return response;
      }
      else{
        return result;
      }
    },
  },
  getters: {
    DeviceToken({ state }) {
      return state.deviceToken;
    },
    IsTokenSaved({ state }) {
      return state.isTokenSaved;
    },
  },
});

export default device;
