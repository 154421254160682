/**
* @file Allows uploading, cropping (with automatic resizing) and exporting
* of images.
* @author Billy Brown
* @license MIT
* @version 2.1.0
*/

/** Class used for uploading images. */
class Uploader {
  /**
  * <p>Creates an Uploader instance with parameters passed as an object.</p>
  * <p>Available parameters are:</p>
  * <ul>
  *  <li>exceptions {function}: the exceptions handler to use, function that takes a string.</li>
  *  <li>input {HTMLElement} (required): the file input element. Instantiation fails if not provided.</li>
  *  <li>types {array}: the file types accepted by the uploader.</li>
  * </ul>
  *
  * @example
  * var uploader = new Uploader({
  *  input: document.querySelector('.js-fileinput'),
  *  types: [ 'gif', 'jpg', 'jpeg', 'png' ]
  * });
  * *
  * @param {object} options the parameters to be passed for instantiation
  */
  constructor(options) {
    if (!options.input) {
      throw '[Uploader] Missing input file element.';
    }
    this.fileInput = options.input;
    this.types = options.types || ['gif', 'jpg', 'jpeg', 'png'];
  }

  /**
  * Listen for an image file to be uploaded, then validate it and resolve with the image data.
  */
  listen(resolve, reject) {
    this.fileInput.onchange = e => {
      // Do not submit the form
      e.preventDefault();

      // Make sure one file was selected
      if (!this.fileInput.files || this.fileInput.files.length !== 1) {
        reject('[Uploader:listen] Select only one file.');
      }

      let file = this.fileInput.files[0];
      let reader = new FileReader();
      // Make sure the file is of the correct type
      if (!this.validFileType(file.type)) {
        reject(`[Uploader:listen] Invalid file type: ${file.type}`);
      }
      else {
        // Read the image as base64 data
        reader.readAsDataURL(file);
        // When loaded, return the file data
        reader.onload = e => resolve(e.target.result);
      }
    };
  }

  /** @private */
  validFileType(filename) {
    // Get the second part of the MIME type
    let extension = filename.split('/').pop().toLowerCase();
    // See if it is in the array of allowed types
    return this.types.includes(extension);
  }
}

export default Uploader;
