import { createStore } from 'framework7';
import { http } from '../api/config.js';

const car = createStore({
  state: {
    item: {},
    list: [],
  },
  actions: {
//Mutations
    set_item({ state }, item) {
      state.item = item;
    },
//End mutations
    async getCarData({ dispatch }, carId){
      let response = [];
      let result = {};

      result = await http.get(`car/data/${carId}`);
      if (result.status === 200){
        response = result.data;
        return response;
      }
      else{
        return result;
      }
    },
    async getCarList({ dispatch }, { page = 1, rows = 10, sidx = 0, sdir = 0, filter = "" } = {}){
      let response = [];
      let result = {};

      result = await http.post(`car/list/${page}/${rows}/${sidx}/${sdir}`, filter, { headers: {'content-type': 'application/x-www-form-urlencoded'} });
      if (result.status === 200){
        response = result.data;
        return response;
      }
      else{
        return result;
      }
    },
    async add({ dispatch }, data){
      let response = [];
      let result = {};

      result = await http.post(`car/create`, data, { headers: {'content-type': 'application/x-www-form-urlencoded'} });
      if (result.status === 201){
        response = result.data;
        return response;
      }
      else{
        return result;
      }
    },
    async modifyData({ dispatch }, data){
      let response = [];
      let result = {};

      result = await http.post(`car/modify`, data, { headers: {'content-type': 'application/x-www-form-urlencoded'} });
      if (result.status === 200){
        response = result.data;
        return response;
      }
      else{
        return result;
      }
    },
    async toggleUse({ dispatch }, data){
      let response = [];
      let result = {};

      result = await http.post(`car/use`, data, { headers: {'content-type': 'application/x-www-form-urlencoded'} });
      if (result.status === 200){
        response = result.data;
        return response;
      }
      else{
        return result;
      }
    },
    async getCarOwner({ dispatch }, ownerId){
      let response = [];
      let result = {};

      result = await http.get(`car/owner/${ownerId}`);
      if (result.status === 200){
        response = result.data;
        return response;
      }
      else{
        return result;
      }
    }
  },
  getters: {
    Item({ state }) {
      return state.item;
    }
  },
});

export default car;
