import AdminPanelPage from '../../pages/admin/panel.f7.html';
import CarPricingDashboardPage from '../../pages/admin/car-pricing/dashboard.f7.html';
import CarPricingEditPage from '../../pages/admin/car-pricing/edit.f7.html';

import Check from '../controllers/check';

const admin = [
  {
    name: 'admin-panel',
    path: '/admin-panel/',
    component: AdminPanelPage,
    allowedRoles: [1],
    beforeEnter: [Check.authorization, Check.permission],
  },
  {
    name: 'car-pricing-edit',
    path: '/car-pricing-edit/',
    popup: {
      component: CarPricingEditPage,
    },
    allowedRoles: [1],
    beforeEnter: [Check.authorization, Check.permission],
  },
  {
    name: 'car-pricing-dashboard',
    path: '/car-pricing-dashboard/',
    component: CarPricingDashboardPage,
    allowedRoles: [1],
    beforeEnter: [Check.authorization, Check.permission],
  },
];

export default admin;
