const dictionaryES = {
  originalWriting: 'Español',
  general: {
    user: 'Usuario',
    email: 'Correo',
    password: 'Contraseña',
    close: 'Cerrar',
    save: 'Guardar',
    done: 'Listo',
    cancel: 'Cancelar',
    accept: 'Aceptar',
    decline: 'Declinar',
    cut: 'Cortar',
    browse: 'Buscar',
    chooseFileLabel: 'Elije archivo...',
    selectedCountLabel: 'archivos elegidos',
    from: 'desde',
    perDay: 'por día',
    select: 'Elige',
    owner: 'Propietario',
    details: 'Detalles',
    sizeList:[
      'Ninguno',
      'Pequeño',
      'Mediano',
      'Grande'
    ],
    options: 'Opciones',
    status: 'Estado',
    pay: 'Pagar',
    edit: 'Editar',
  },
  user: {
    login: {
      title: 'Ingresar',
      userMailLabel: 'Usuario o E-mail',
      userMailPlaceHolder: 'Usuario o e-mail',
      userPasswordLabel: 'Contraseña',
      userPasswordPlaceholder: 'Contraseña',
      loginButtonLabel: 'Ingresar',
      newUserLabel: 'Registrarse',
      successfulLabel: 'Has ingresado'
    },
    newAccount: {
      titleDialog: 'Registrar nuevo usuario',
      titleForm: 'Nuevo usuario',
      userNameLabel: 'Usuario',
      userNamePlaceHolder: 'Usuario (mínimo 8 carácteres)',
      userEmailLabel: 'E-mail',
      userEmailPlaceholder: 'E-mail',
      userPasswordLabel: 'Contraseña',
      userPasswordAllowedCharsMessage: 'Caracteres permitidos: Mayúsculas, minúsculas, numeros y @$!%*?&',
      userPasswordPlaceholder: 'Contraseña (mínimo 8 carácteres)',
      userPasswordConfirmLabel: 'Confirmar Contraseña',
      userPasswordConfirmPlaceHolder: 'Confirmar contraseña',
      userAgreementCheckbox: 'Acepto el acuerdo',
      userAgreementViewDoc: 'Ver acuerdo',
      userMissingFieldsMessage: 'Faltan algunos campos, por favor ingreselos.',
      userMinLengthMessage: 'El usuario debe tener al menos 8 caracteres.',
      userEmailValidMessage: 'Por favor, ingrese un email válido.',
      userPasswordValidationsMessage: 'La contraseña no es muy fuerte. Debe tener al menos 8 caracteres <br/> Debe tener al menos 1 letra minúscula.<br/>Debe tener al menos 1 mayúscula<br/>Debe tener un número.<br/>Debe tener al menos un caracter de estos: @$!%*?&',
      userPasswordMismatchMessage: 'La contraseñas no concuerdan.',
      userAcceptAgreementMessage: 'Debes haber leído y aceptar nuestro acuerdo.',
      userSuccesfullyCreatedMessage: 'El usuario ha sido creado. Por favor, revise su correo electrónico y habilate su cuenta.',
    },
    edit: {
      title: 'Editando Cuenta de Usuario',
      tabs: [
        'Cuenta Usuario',
        'Personal',
        'Ubicación',
        'Auto',
      ],
      account: {
        avatarSelection: 'Selección de ávatar',
        avatarUploadImage: 'Subir imagen',
        avatarSave: 'Guardar',
        uploadAvatarOkMessage: 'Imgan de ávatar actualizada',
      },
      personal: {
        title: 'Información Personal',
        contactFirstName: 'Nombre',
        contactLastName: 'Apellido',
        contactGenderLabel: 'Género',
        contactGenderMale: 'Masculino',
        contactGenderFemale: 'Femenino',
        contactBirthDateLabel: 'Fecha nacimiento',
        contactNotAdultMessage: 'La edad ingresada no es la de un adulto (21)',
        contactSave: 'Guardar',
        viewLicense: 'Ver Licencia',
        licenseSave: 'Guardar Licencia',
        uploadLicenseLabel: 'Sube tu licencia de conducir (frente y reverso)',
        updateContactOkMessage: 'Contacto actualizado',
        uploadLicenseRequiredLengthFilesMessage: 'Selecciona las imágenes del frente y reverso de licencia de conducir',
        uploadLicenseFileOkMessage: 'Licencia de conducir actualizada',
      },
      location: {
        title: 'Dirección',
        streetLabel: 'Calle',
        streetPlaceHolder: 'Nombre de la calle',
        externalNumber: 'Número Exterior',
        externalNumberPlaceHolder: 'Número exterior',
        district: 'Colonia',
        districtPlaceHolder: 'Colonia',
        zipCode: 'Código Postal',
        zipCodePlaceHolder: 'Código Postal',
        phone: 'Celular',
        phonePlaceHolder: 'Agregar tu número de celular',
        email: 'Correo',
        emailPlaceHolder: 'Agrega tu correo',
        locality: 'Municipio',
        localityPlaceHolder: 'Municipio',
        addressSave: 'Guardar Dirección',
        updatedContactAdressMessage: 'La direción del contacto fue actualizada',
      },
    },
  },
  home: {
    title: 'Inicio',
    panelLeft: {
      userLabel: 'Usuario',
      userNewAccountLabel: 'Nueva Cuenta',
      userResetPasswordLabel: 'Recuperar Contraseña',
      userEditAccountLabel: 'Editar Cuenta',
      item: 'Préstamos (Pruebas)',
    },
    panelRight: {
      userLabel: 'Usuario',
      userRegister: 'Registrar',
      sessionLabel: 'Sesión',
      sessionLogInLabel: 'Ingresar',
      sessionLogOutLabel: 'Salir',
      lightThemeLabel: 'Tema Claro',
      selectLanguageLabel: 'Idioma',
      sessionLogOutMessage: 'Ha cerrado sesión.',
      allowWebNotifications: 'Notificaciones (web)',
      notAllowedWebNotificationsMessage: 'Si no permites notificaciones, no recibrás alguna cuando te soliciten tu auto o cuando soliciten el tuyo',
    },
    topMenu: {
      offer: 'Ofertas',
      lend: 'Préstamos',
      howItWorks: '¿Como funciona?',
      contact: 'Contacto',
      notifications: 'Notificaciones',
    },
    main: {
      borrowCarLabel: 'Prestar un auto',
      sloganLabel: 'Juntos hacemos el mundo más sostenible.',
      whatCarsLabel: '¿Que autos hay disponibles?',
      location: 'Ubicación',
      date: 'Fecha',
      viewAvailabilityLabel: 'Ver Disponibilidad',
      flexible: 'Flexible',
      durable: 'Durable',
      transparent: 'Transparente',
      loanOfferLabel: 'Oferta de préstamos'
    },
  },
  car: {
    dashboard: {
      title: 'Panel',
      carList: 'Lista de autos',
      additionalData: 'Datos adicionales',
      aboutLender: 'Sobre el prestamista',
    },
    features: {
      titleShort: 'Caraterísticas',
      titleDialog: 'Elije características',
      isUsed: '¿Se usa?',
      name: 'Nombre',
      value: 'Valor',
    },
    edit: {
      title: 'Edición de Auto',
      useMyCarLabel: 'Usar mí auto',
      manufacturerLabel: 'Fabricante',
      modelLabel: 'Modelo',
      yearLabel: 'Año',
      priceLabel: 'Precio',
      carIsEnsured: 'Mi carro esta asegurado en todo',
      descriptionLabel: 'Descripción',
      carSave: 'Guardar',
      carImageUploadLabel: 'Imagen del auto',
      carImageUploadSave: 'Imágenes',
      carPlateUploadLabel: 'Placas',
      carPlateUploadSave: 'Placas',
      carFeaturePopupLabel: 'Características',
      carImagesPhotoBrowserLabel: 'Ver Imágenes',
      carImageFileListLabel: 'Guardar Lista',
      uploadCarImagesLabel: 'Sube las imágenes de tu auto',
      updatedCarMessage: 'Datos del auto actualizados',
      selectFileToUpload: 'Elije un archivo a subir',
      carImageUploadedOkMessage: 'Imagen del auto subida',
      carPlateUploadedOkMessage:'Placa del auto subida',
      uploadCarImagesLimitMessage: 'Debes elegir 6 imágenes de tu carro',
      carImageListUploadedOkMessage: 'Lista de imágenes de tu auto subidas',
      carIsUsedStatusMessage: 'El carro esta en un prestamo actualmente, no puede ser actualizado ahora',
      carSize: 'Tamaño de auto',
      checkSizeMessage: 'Seleccione un tamaño para sugerir un precio',
      recommendedPriceMessage: 'El precio debe estar entre %% y %%, precio actual: %%',
      freeKilometers: "Kilometros libres",
    },
  },
  loan: {
    title: 'Préstamo',
    lends: 'Prestamos',
    borrows: 'Solicitudes',
    tabs: [
      'Solicitud',
      'Decisión',
      'Pago',
      'Aseguranza',
      'Cita',
      'Encuentro',
      'Detener',
      'Regresar',
      'Calificar'
    ],
    dashboard: {
      carLoanTag: 'Etiqueta',
      lender: 'Prestador',
      borrower: 'Solicitante',
      startDate: 'Fecha Inicio',
      endDate: 'Fecha Entrega',
      daysToBorrow: 'Días a Prestar',
      totalToPay: 'Total a Pagar',
      discounts: 'Descuentos',
      isGoingAbroad: '¿Va al extrajero?',
      returningKilometers: 'Kms al Regreso',
      currentKilometers: 'Kms Actualmente',
      pricePerDay: 'Precio por Día',
    },
    request: {
      loanPeriod: 'Período de Préstamo',
      loanRangeDatePlaceHolder: 'Elije período de préstamo',
      onlyDriver: 'Manejaré solo yo',
      driveAbroad: '¿Viajarás al extrajero? (+€1 por día)',
      extraDriverData: 'Conductor Extra',
      messageRangeDate: 'Elije un rango de fechas que viajarás',
      onEmptyFormField: 'Por favor ingrese el campo: [ %% ] del copiloto',
      confirmSaveRequest: 'Por favor, añade tu contraseña para confirmar que aceptas solicitar este coche.',
      messageSavedRequest: 'El prestamista necesita aceptar tu petición',
      onLoanPeriodAtLeast3Days: 'El período de préstamo debe ser establecido en al menos 3 días a partir de hoy',
      onLoadPeriodGreater30Days: 'El plazo del préstamo no puede ser superior a 30 días',
    },
    deciding: {
      confirmCancelSaveDeciding: '¿Quieres CANCELAR el préstamo de este coche?',
      messageCancelDeciding: 'El prestamista se ha negado a prestar el coche. Cancelando operaciones...',
      confirmAcceptSaveDeciding: 'Por favor, añade tu contraseña para confirmar que aceptas prestar tu coche.',
      messageAcceptlDeciding: 'Se requiere depósito de una póliza de seguro.',
    },
    statusLabel: {
      1: 'Solicitado',
      2: 'Aceptado',
      3: 'Pagado',
      4: 'Siguiente',
      12: 'Cancelado por Prestador',
      13: 'Cancelado por Solicitante',
    },
  },
  admin:{
    title: 'Panel de Control',
    sections:{
      carPricing: 'Establecimiento de Precios',
      carPricingEdit: 'Edicion de Precio',
    },
  }
};

export default dictionaryES;
