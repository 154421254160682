import ForbiddenPage from '../../pages/forbidden.f7.html';

/*Must be in last position*/
const forbidden = [
  {
    name: 'forbidden',
    path: '/forbidden/',
    component: ForbiddenPage
  }
];

export default forbidden;
