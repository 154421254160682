import Localization from 'localizationjs/dist/localization.min.js';

import dictionaryEN from './en.js';
import dictionaryES from './es.js';
import dictionaryNL from './nl.js';
import dictionaryDE from './de.js';

// create a locale manager
const locale = new Localization({ defaultLocale: "en" });

// add the dictionary for english language
locale.addDict("en", dictionaryEN);

// add the dictionary for nederland language
locale.addDict("nl", dictionaryNL);

// add the dictionary for spanish language
locale.addDict("es", dictionaryES);

// add the dictionary for spanish language
locale.addDict("de", dictionaryDE);

export default locale;
