import carPricing from "../stores/car-pricing";

const dictionaryEN = {
  originalWriting: 'English',
  general: {
    user: 'User',
    email: 'E-mail',
    password: 'Password',
    close: 'Close',
    save: 'Save',
    done: 'Done',
    accept: 'Accept',
    cancel: 'Cancel',
    decline: 'Decline',
    cut: 'Cut',
    browse: 'Browser',
    chooseFileLabel: 'Choose file...',
    selectedCountLabel: 'files selected',
    from: 'from',
    perDay: 'per day',
    select: 'Select',
    owner: 'Owner',
    details: 'Details',
    sizeList:[
      'None',
      'Small',
      'Medium',
      'Large'
    ],
    options: 'Options',
    status: 'Status',
    pay: 'Pay',
    edit: 'Edit',
  },
  user: {
    login: {
      title: 'Login',
      userMailLabel: 'User or Email',
      userMailPlaceHolder: 'User name or email',
      userPasswordLabel: 'Password',
      userPasswordPlaceholder: 'Password',
      loginButtonLabel: 'Log In',
      newUserLabel: 'New Account',
      successfulLabel: 'You are currently logged'
    },
    newAccount: {
      titleDialog: 'Register new user',
      titleForm: 'New user',
      userNameLabel: 'User',
      userNamePlaceHolder: 'User name (min 8 characters)',
      userEmailLabel: 'E-mail',
      userEmailPlaceholder: 'E-mail',
      userPasswordLabel: 'Password',
      userPasswordAllowedCharsMessage: 'Allowed characters: Uppercase,lowercase, numbers and @$!%*?&',
      userPasswordPlaceholder: 'Password (min 8 characters)',
      userPasswordConfirmLabel: 'Password Confirm',
      userPasswordConfirmPlaceHolder: 'Password confirm',
      userAgreementCheckbox: 'I fully accept agreement',
      userAgreementViewDoc: 'View agreement',
      userMissingFieldsMessage: 'Some fields are missing, please fill required fields.',
      userMinLengthMessage: 'User name must be at least 8 characters.',
      userEmailValidMessage: 'Please, enter a valid email.',
      userPasswordValidationsMessage: 'Password is not strong enough. Must have at least 8 characters <br/> Must have at least 1 lower case letter.<br/>Must have at least 1 upper case letter<br/>Must have one number.<br/>It must have at least one of these characters: @$!%*?&',
      userPasswordMismatchMessage: 'Password mismatch.',
      userAcceptAgreementMessage: 'You must accept have read and accept our agreement terms.',
      userSuccesfullyCreatedMessage: 'User has been created. Please, open your email account and enable it.',
    },
    edit: {
      title: 'User Account Edition',
      tabs: [
        'User Account',
        'Personal',
        'Location',
        'Car Data',
      ],
      account: {
        avatarSelection: 'Avatar edition/selection',
        avatarUploadImage: 'Upload image',
        avatarSave: 'Save',
        uploadAvatarOkMessage: 'Avatar image successfully updated',
      },
      personal: {
        title: 'Personal Data',
        contactFirstName: 'First Name',
        contactLastName: 'Last Name',
        contactGenderLabel: 'Gender',
        contactGenderMale: 'Male',
        contactGenderFemale: 'Female',
        contactBirthDateLabel: 'Birth Date',
        contactNotAdultMessage: 'Driver age is not a legal adult',
        contactSave: 'Save Info',
        viewLicense: 'View License',
        licenseSave: 'Save License',
        uploadLicenseLabel: 'Upload your drivers license (front and back)',
        updateContactOkMessage: 'Contact data updated',
        uploadLicenseRequiredLengthFilesMessage: 'Select front and back images for your driver\'s license',
        uploadLicenseFileOkMessage: 'Driver\'s license updated',
      },
      location: {
        title: 'Address',
        streetLabel: 'Street',
        streetPlaceHolder: 'Street name',
        externalNumber: 'External Number',
        externalNumberPlaceHolder: 'Address external number',
        district: 'District',
        districtPlaceHolder: 'District',
        zipCode: 'Zip Code',
        zipCodePlaceHolder: 'Zip code',
        phone: 'Phone',
        phonePlaceHolder: 'Add your phone number',
        email: 'Email',
        emailPlaceHolder: 'Add your email',
        locality: 'Locality',
        localityPlaceHolder: 'Locality',
        addressSave: 'Save Address',
        updatedContactAdressMessage: 'Contact address data updated',
      },
    },
  },
  home: {
    title: 'Home',
    panelLeft: {
      userLabel: 'User',
      userNewAccountLabel: 'New Account',
      userResetPasswordLabel: 'Reset Password',
      userEditAccountLabel: 'Edit Account',
      item: 'Loans (test)',
    },
    panelRight: {
      userLabel: 'User',
      userRegister: 'Register',
      sessionLabel: 'Session',
      sessionLogInLabel: 'Login',
      sessionLogOutLabel: 'Logut',
      lightThemeLabel: 'Light Theme',
      selectLanguageLabel: 'Language',
      sessionLogOutMessage: 'You\'ve logged out.',
      allowWebNotifications: 'Notifications (web)',
      notAllowedWebNotificationsMessage: 'If you don\'t allow notifications, you will be not be able to receive messages when you a get request for your car or when someone ask for yours',
    },
    topMenu: {
      offer: 'Offer',
      lend: 'Lend',
      howItWorks: 'How it works?',
      contact: 'Contact',
      notifications: 'Notifications',
    },
    main: {
      borrowCarLabel: 'Borrow a car',
      sloganLabel: 'Together we make the world more sustainable.',
      whatCarsLabel: 'What cars are available?',
      location: 'Location',
      date: 'Date',
      viewAvailabilityLabel: 'View Availability',
      flexible: 'Flexible',
      durable: 'Durable',
      transparent: 'Transparent',
      loanOfferLabel: 'Loan offer'
    },
  },
  car: {
    dashboard: {
      title: 'Dashboard',
      carList: 'Car list',
      additionalData: 'Additional data',
      aboutLender: 'About lender',
    },
    features: {
      titleShort: 'Features',
      titleDialog: 'Select Car Features',
      isUsed: 'Used?',
      name: 'Name',
      value: 'Value',
    },
    edit: {
      title: 'Car Data Edition',
      useMyCarLabel: 'I want to use my car',
      manufacturerLabel: 'Manufacturer',
      modelLabel: 'Model',
      yearLabel: 'Year',
      priceLabel: 'Price',
      carIsEnsured: 'My car is all-risk insured',
      descriptionLabel: 'Description',
      carSave: 'Save Info',
      carImageUploadLabel: 'Car image',
      carImageUploadSave: 'Car image',
      carPlateUploadLabel: 'Plate image',
      carPlateUploadSave: 'Car Plate',
      carFeaturePopupLabel: 'Car Features',
      carImagesPhotoBrowserLabel: 'Car Images',
      carImageFileListLabel: 'Save List',
      uploadCarImagesLabel: 'Upload your cars images',
      updatedCarMessage: 'Car data successfully updated',
      selectFileToUpload: 'Select a file to upload',
      carImageUploadedOkMessage: 'Car image successfully uploaded',
      carPlateUploadedOkMessage:'Car plate successfully uploaded',
      uploadCarImagesLimitMessage: 'You must select 6 images for your car',
      carImageListUploadedOkMessage: 'Car image list successfully uploaded',
      carIsUsedStatusMessage: 'Car is on a borrow request right now, can\'t be updated right now',
      carSize: 'Car Size',
      checkSizeMessage: 'Select a car size to determine suggested price',
      recommendedPriceMessage: 'Price must be between %% and %%, actual price: %%',
      freeKilometers: "Free Kilometers",
    },
  },
  loan: {
    title: 'Loan',
    lends: 'Lends',
    borrows: 'Borrows',
    tabs: [
      'Request',
      'Deciding',
      'Payment',
      'Insurance',
      'Appointment',
      'Meeting',
      'Stopping',
      'Returning',
      'Rating'
    ],
    dashboard: {
      carLoanTag: 'Label',
      lender: 'Lender',
      borrower: 'Borrower',
      startDate: 'Start Date',
      endDate: 'End Date',
      daysToBorrow: 'Days to Borrow',
      totalToPay: 'Total to Pay',
      discounts: 'Discounts',
      isGoingAbroad: 'Is Going Abroad',
      returningKilometers: 'Returning km\'s',
      currentKilometers: 'Current km\'s',
      pricePerDay: 'Price per Day',
    },
    request: {
      loanPeriod: 'Loan Period',
      loanRangeDatePlaceHolder: 'Select date range',
      onlyDriver: 'I\'m the only driver',
      driveAbroad: 'Are you going to drive abroad? (+€1 per day)',
      extraDriverData: 'Extra driver data',
      messageRangeDate: 'Select a range of dates you will be traveling',
      onEmptyFormField: 'Please fill extra driver data, field: %%',
      confirmSaveRequest: 'Please, add your password to confirm that you agree to request for this car',
      messageSavedRequest: 'Lender will need to accept your request',
      onLoanPeriodAtLeast3Days: 'The loan period must be set at least 3 days from today',
      onLoadPeriodGreater30Days: 'Loan period can not be greater than 30 days',
    },
    deciding: {
      confirmCancelSaveDeciding: 'Would you like to CANCEL to lend this car?',
      messageCancelDeciding: 'Loaner has declined to loan car. Canceling operations ...',
      confirmAcceptSaveDeciding: 'Please, add your password to confirm that you agree to borrow you car',
      messageAcceptlDeciding: 'You are required deposit of an insurance policy',
    },
    statusLabel: {
      1: 'Requested',
      2: 'Acepted',
      3: 'Paid',
      4: 'Next',
      12: 'Canceled by Lender',
      13: 'Canceled by Borrower',
    },
  },
  admin:{
    title: 'Admin Panel',
    sections:{
      carPricing: 'Smart Pricing',
      carPricingEdit: 'Pricing Edition',
    },
  }
};

export default dictionaryEN;
