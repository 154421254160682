import CarLoanDashboardPage from '../../pages/loan/dashboard.f7.html';
import CarLoanItemPage from '../../pages/loan/item.f7.html';
import CarLoanPaymentRetunrPage from '../../pages/loan/components/payment-return.f7.html';

import Check from '../controllers/check.js';

const loan = [
  {
    name: 'loan-dashboard',
    path: '/loan-dashboard/',
    component: CarLoanDashboardPage,
    allowedRoles: [1, 4],
    beforeEnter: [Check.authorization, Check.permission],
  },
  {
    name: 'loan-item',
    path: '/loan-item/',
    component: CarLoanItemPage,
    allowedRoles: [1, 4],
    beforeEnter: [Check.authorization, Check.permission],
  },
  {
    name: 'loan-item-payment-return',
    path: '/payment-return/',
    component: CarLoanPaymentRetunrPage,
    allowedRoles: [4],
    beforeEnter: [Check.authorization, Check.permission],
  },
];

export default loan;
