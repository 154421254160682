import { createStore } from 'framework7';
import { localForage } from '../api/encrypt';
import locale from '../locale/config.js';

import * as dayjs from 'dayjs';
import * as relativeTime from 'dayjs/plugin/relativeTime';
import dayjsLocales from '../locale/dayjs.js';

const language = createStore({
  state: {
    item: {},
    name: 'en',
  },
  actions: {
//Mutations
    changeLanguage({ state }, {name, item}) {
      state.name = name;
      state.item = item;
    },
//End mutations
    async initLanguage({ dispatch }){
      let language = await localForage.getItem('language');

      if (language == null){
        language = 'en';
        await localForage.setItem('language', language);
      }
      await dispatch('checkLanguage');
    },
    async checkLanguage({ dispatch }){
      let language = await localForage.getItem('language');
      locale.setCurrentLocale(language);

      await dayjsLocales[language]();
      dayjs.extend(relativeTime);
      dayjs.locale(language);

      dispatch('changeLanguage', { name: language, item: locale });
    },
    async setLanguage({ dispatch }, language){
      await localForage.setItem('language', language);
      locale.setCurrentLocale(language);
      dispatch('changeLanguage', {name: language, item: locale});
    },
  },
  getters: {
    Name({ state }) {
      return state.name;
    },
    Item({ state }){
      return state.item;
    },
  },
})

export default language;
