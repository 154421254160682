import { createStore } from 'framework7';
import { http } from '../api/config.js';

const notification = createStore({
  state: {
    item: {},
    list: [],
  },
  actions: {
//Mutations
    set_item({ state }, item) {
      state.item = item;
    },
//End mutations
    async getSenderList({ dispatch }){
      let response = [];
      let result = {};

      result = await http.get(`notification/get/sender`);
      if (result.status === 200){
        response = result.data;
        return response;
      }
      else{
        return result;
      }
    },

    async getReceiverList({ dispatch }){
      let response = [];
      let result = {};

      result = await http.get(`notification/get/receiver`);
      if (result.status === 200){
        response = result.data;
        return response;
      }
      else{
        return [];
      }
    },

    async createNew({ dispatch }, data){
      let response = [];
      let result = {};

      result = await http.post(`notification/create`, data), { headers: {'content-type': 'application/x-www-form-urlencoded'} };
      if (result.status === 201){
        response = result.data;
        return response;
      }
      else{
        return result;
      }
    },

    async setAsRead({ dispatch }, data){
      let response = [];
      let result = {};

      result = await http.post(`notification/set-as-read`, data, { headers: {'content-type': 'application/x-www-form-urlencoded'} });
      if (result.status === 201){
        response = result.data;
        return response;
      }
      else{
        return result;
      }
    },
  },
  getters: {
    Item({ state }) {
      return state.item;
    }
  },
});

export default notification;
