import AboutPage from '../pages/about.f7.html';

import home from './routes/home.js';
import users from './routes/users.js';
import cars from './routes/cars.js';
import loan from './routes/loan.js';
import admin from './routes/admin.js';
import forbidden from './routes/forbidden.js';
import not_found from './routes/not-found.js';

let routes = [
  {
    name: 'about',
    path: '/about/',
    component: AboutPage,
  },
  ...home,
  ...users,
  ...cars,
  ...loan,
  ...admin,
  ...forbidden,
  ...not_found,
]

export default routes;
