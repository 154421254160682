import Framework7, { getDevice } from './framework7-custom.js';

// Import F7 Styles
import '../css/framework7-custom.less';

// Import Icons and App Custom Styles
import '../css/icons.css';
import '../css/app.less';

// Import Croppie css
import 'croppie/croppie.css';

// Import file-upload-with-preview css file
import '../css/file-upload-with-preview.css';

// Import Cordova APIs
import cordovaApp from './cordova-app.js';

// Import Routes
import routes from './routes.js';

// Import htpp (axios) instance 
import { http } from './api/config.js';

// Import main app component
import App from '../app.f7.html';

// Import user store
import user from './stores/user.js';

//Import firebase configuration file
import { messaging, webPushCertificateKey, onMessage, getToken } from './notifications/firebase-config.js';

//Import device store
import devices from './stores/device.js';

var device = getDevice();
var app = new Framework7({
  name: 'DeLeen Auto',      // App name
  theme: 'auto',           // Automatic theme detection
  el: '#app',              // App root element
  component: App,          // App main component
  id: 'com.deleenauto.app', // App bundle ID
  routes: routes,          // App routes
  serviceWorker: {         // Register service worker
    path: '/service-worker.js',
    //scope: 'process.env.END_POINT_URL/',
  },

  // view: {
  //   browserHistory: true,
  // },

  dialog: {
    buttonOk: 'Accept',
    buttonCancel: 'Cancel',
  },

  toast: {
    closeTimeout: 2500,
    closeButton: true,
    closeButtonText: `<i class="icon material-symbols-outlined color-red">cancel</i>`
  },

  smartSelect:{
    openIn: "popup", 
    searchbar: "true",
    popupCloseLinkText: `<i class="icon material-symbols-outlined color-red">cancel</i>`,
  },

  // Input settings
  input: {
    scrollIntoViewOnFocus: device.cordova && !device.electron,
    scrollIntoViewCentered: device.cordova && !device.electron,
  },
  // Cordova Statusbar settings
  statusbar: {
    iosOverlaysWebView: true,
    androidOverlaysWebView: false,
  },
  on: {
    init: async function () {
      var f7 = this;
      if (f7.device.cordova) {
        // Init cordova APIs (see cordova-app.js)
        cordovaApp.init(f7);
      }
      //console.log('Device: ', f7.device);
      //Intercept axios call to determine if it gets (401 Unauthorized) response
      http.interceptors.response.use(undefined, function (err) {
        return new Promise(function (resolve, reject) {
          if (err.status === 401 && err.config && !err.config.__isRetryRequest) {
            console.log("Auto saliendo...");
            user.dispatch('logOut').then( () => { } ); //Untested
          }
          throw err;
        });
      });
      await user.dispatch('checkData');

      if (f7.device.desktop && user.getters.isLogged.value){
        onMessage(messaging, (payload) => {
          //console.log('Message received. ', payload);
          // if app is open and focus then notification data will receive here
          // keep in mind if message receive here, it will not notify in background
          // so here, use the message data however you want
          let notificationFull;
          let notificationTitle = 'notification' in payload ? payload.notification.title : payload.data.title;
          let notificationBody  = 'notification' in payload ? payload.notification.body  : payload.data.body;
      
          if (!notificationFull) {
            notificationFull = f7.notification.create({
              icon: `<i class="icon material-symbols-outlined color-blue">notifications_active</i>`,
              title: notificationTitle,
              text: notificationBody,
              //titleRightText: 'now',
              //subtitle: 'This is a subtitle',
              closeOnClick: true,
              closeButton: true,
              //closeTimeout: 4000,
            });
          }
          // Open it
          notificationFull.open();
        });
        try{
          let currentToken = await getToken(messaging, { vapidKey: webPushCertificateKey });
          if (currentToken) {
            // Send the token to your server and update the UI if necessary
            const response = await devices.dispatch('sendTokenToServer', currentToken);
            if (response.status !== undefined && response.status !== 201){
              console.log(response.data.messages.error);
              return;
            }
          }
          else {
            // Show permission request UI
            console.log('No registration token available. Request permission to generate one.');
            let result = await Notification.requestPermission();
            if (result === 'granted') {
              console.log('Notification permission granted.');
            }
            else{
              console.log("Notification permission denied");
            }
          }
        }
        catch(err){
          console.log('An error occurred while retrieving token. ', err);
        }
      }
      if (f7.device.cordova && f7.device.android && f7.device.androidChrome){
        window.FirebasePlugin.onMessageReceived((payload) => {
          //console.log("Data:", payload)
          let notificationFull;
          if (!notificationFull) {
            notificationFull = f7.notification.create({
              icon: `<i class="material-symbols-outlined">notifications_active</i>`,
              title: payload.title,
              text: payload.body,
              //titleRightText: 'now',
              //subtitle: 'This is a subtitle',
              closeOnClick: true,
              closeButton: true,
              //closeTimeout: 4000,
            });
          }
          // Open it
          notificationFull.open();
        });
      }
    },
  },
});
