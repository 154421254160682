import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

/* Personal Development data */
// const firebaseConfig = {
//   apiKey: process.env.FCM_API_KEY,
//   authDomain: process.env.FCM_AUTH_DOMAIN,
//   databaseURL: process.env.FCM_DB_URL,
//   projectId: process.env.FCM_PROJECT_ID,
//   storageBucket: process.env.FCM_STORAGE_BUCKET,
//   messagingSenderId: process.env.FCM_MESSAGING_SENDER_ID,
//   appId: process.env.FCM_APP_ID
// };

/* DeLeenAuto Develepment data */
/* No use of 'databaseURL' here */

const firebaseConfig = {
  apiKey: process.env.FCM_API_KEY,
  authDomain: process.env.FCM_AUTH_DOMAIN,
  projectId: process.env.FCM_PROJECT_ID,
  storageBucket: process.env.FCM_STORAGE_BUCKET,
  messagingSenderId: process.env.FCM_MESSAGING_SENDER_ID,
  appId: process.env.FCM_APP_ID
};

const webPushCertificateKey = process.env.FCM_WEB_PUSH_KEY;

const fcm = initializeApp(firebaseConfig);

// Initialize Firebase Cloud Messaging and get a reference to the service
const messaging = getMessaging(fcm);

export { messaging, webPushCertificateKey, onMessage, getToken };
