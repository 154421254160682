import { createStore } from 'framework7';
import { http } from '../api/config.js';

const carManufacturer = createStore({
  state: {
    item: {},
    list: [],
  },
  actions: {
//Mutations
    set_item({ state }, item) {
      state.item = item;
    },
//End mutations

    /*async getCarData({ dispatch }, carId){
      let response = [];
      let result = {};

      result = await http.get(`car/data/${carId}`);
      if (result.status === 200){
        response = result.data;
        return response;
      }
      else{
        return result;
      }
    },*/

    async getFullList({ dispatch }, filter){
      let response = [];
      let result = {};

      result = await http.get(`car/manufacturer/list`);
      if (result.status === 200){
        response = result.data;
        return response;
      }
      else{
        return result;
      }
    },

    /*async add({ dispatch }, data){
      let response = [];
      let result = {};

      result = await http.post(`car/create/`, data);
      if (result.status === 201){
        response = result.data;
        return response;
      }
      else{
        return result;
      }
    },

    async modifyData({ dispatch }, data){
      let response = [];
      let result = {};

      result = await http.post(`car/modify/`, data);
      if (result.status === 201){
        response = result.data;
        return response;
      }
      else{
        return result;
      }
    },*/
  },
  getters: {
    Item({ state }) {
      return state.item;
    }
  },
});

export default carManufacturer;
