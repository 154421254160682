import HomePage from '../../pages/home.f7.html';
import LeftPanelPage from '../../pages/home/panel-left.f7.html';

import Check from './../controllers/check.js';
import theme from '../stores/theme.js';
import user from '../stores/user.js';
import languages from '../stores/language.js';
import device from '../stores/device.js';
import carPricing from '../stores/car-pricing.js';

const checkInits = async ( { to, resolve } ) => {
  await theme.dispatch('initTheme', '#app');
  await user.dispatch('checkData');
  await languages.dispatch('initLanguage');
  await device.dispatch('isTokenSentToServer');
  await carPricing.dispatch('getList');
  resolve(to.path);
};

const home = [
  {
    name: 'home',
    path: '/',
    component: HomePage,
    //allowedRoles: [1,9,10,14,15],
    //beforeEnter: [Check.authorization, Check.permission],
    beforeEnter: [checkInits],
  },
  {
    name: 'panel-left-',
    path: '/panel-left/',
    panel: {
      component: LeftPanelPage,
    }
  },
];

export default home;
