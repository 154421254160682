import CarDashboardPage from '../../pages/car/dashboard.f7.html';
import CarEditPage from '../../pages/car/edit.f7.html';
import CarDisplayPage from  '../../pages/car/display.f7.html';
import CarLendingPage from  '../../pages/car/lending.f7.html';

import Check from '../controllers/check';

const cars = [
  {
    name: 'car-edit',
    path: '/car-edit/',
    component: CarEditPage,
    allowedRoles: [1, 4],
    beforeEnter: [Check.authorization, Check.permission],
  },
  {
    name: 'car-display',
    path: '/car-display/',
    component: CarDisplayPage,
    // allowedRoles: [1, 4],
    // beforeEnter: [Check.authorization, Check.permission],
  },
  {
    name: 'car-dashboard',
    path: '/car-dashboard/',
    component: CarDashboardPage,
    // allowedRoles: [1, 4],
    // beforeEnter: [Check.authorization, Check.permission],
  },
  {
    name: 'car-lending',
    path: '/car-lending/',
    component: CarLendingPage,
    allowedRoles: [1, 4],
    beforeEnter: [Check.authorization, Check.permission],
  }
];

export default cars;