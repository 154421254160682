import { createStore } from 'framework7';
import { http } from '../api/config.js';

const carFeatures = createStore({
  state: {
    item: {},
    list: [],
  },
  actions: {
//Mutations
    set_item({ state }, item) {
      state.item = item;
    },
//End mutations

    // Disabled by now, not created
    // async getCarFeatureData({ dispatch }, carFeatureId){
    //   let response = [];
    //   let result = {};

    //   result = await http.get(`car/feature/data/${carFeatureId}`);
    //   if (result.status === 200){
    //     response = result.data;
    //     return response;
    //   }
    //   else{
    //     return result;
    //   }
    // },
    async getCarFeaturesDetailList({ dispatch }, carFeatureDetailId){
      let response = [];
      let result = {};

      result = await http.get(`car/feature/detail/list/${carFeatureDetailId}`);
      if (result.status === 200){
        response = result.data;
        return response;
      }
      else{
        return result;
      }
    },
    async updateSingleFeature({ dispatch }, data){
      let response = [];
      let result = {};

      result = await http.post(`car/feature/detail/modify`, data, { headers: {'content-type': 'application/x-www-form-urlencoded'} });
      if (result.status === 200){
        response = result.data;
        return response;
      }
      else{
        return result;
      }
    },
    async getFeaturesFromCar({ dispatch }, carId){
      let response = [];
      let result = {};

      result = await http.get(`car/feature/detail/list/${carId}`);
      if (result.status === 200){
        response = result.data;
        return response;
      }
      else{
        return result;
      }
    },
    async getFeaturesFromCarLocalized({ dispatch }, data){
      let response = [];
      let result = {};

      result = await http.post(`car/feature/detail/localized/list`, data, { headers: {'content-type': 'application/x-www-form-urlencoded'} });
      if (result.status === 200){
        response = result.data;
        return response;
      }
      else{
        return result;
      }
    },
  },
  getters: {
    Item({ state }) {
      return state.item;
    }
  },
});

export default carFeatures;
