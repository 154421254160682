const dictionaryNL = {
  originalWriting: 'Nederland',
  general: {
    user: 'Gebruiker',
    email: 'E-mail',
    password: 'Wachtwoord',
    close: 'Sluiten',
    save: 'Opslaan',
    done: 'Klaar',
    accept: 'Aanvaarden ',
    cancel: 'Annuleren',
    decline: 'Afwijzen',
    cut: 'Knip',
    browse: 'Browser',
    chooseFileLabel: 'Kies bestand...',
    selectedCountLabel: 'Bestanden geselecteerd',
    from: 'Van',
    perDay: 'per dag',
    select: 'Selecteer',
    owner: 'Eigenaar',
    details: 'Details',
    sizeList:[
      'Geen',
      'Klein',
      'Middel',
      'Groot',
    ],
    options: 'Opties',
    status: 'Status',
    pay: 'Betalen',
    edit: 'Bewerking',
  },
  user: {
    login: {
      title: 'Inloggen',
      userMailLabel: 'Gebruiker of e-mail',
      userMailPlaceHolder: 'Gebruikersnaam of email',
      userPasswordLabel: 'Wachtwoord',
      userPasswordPlaceholder: 'Wachtwoord',
      loginButtonLabel: 'Inloggen',
      newUserLabel: 'Nieuw Account',
      successfulLabel: 'U bent momenteel ingelogd'
    },
    newAccount: {
      titleDialog: 'Registreer een nieuwe gebruiker',
      titleForm: 'Nieuwe gebruiker',
      userNameLabel: 'Gebruikersnaam',
      userNamePlaceHolder: 'Gebruikersnaam (minimaal 8 tekens)',
      userEmailLabel: 'E-mail',
      userEmailPlaceholder: 'E-mail',
      userPasswordLabel: 'Wachtwoord',
      userPasswordAllowedCharsMessage: 'Toegestane tekens: hoofdletters, kleine letters, cijfers en @$!%*?&',
      userPasswordPlaceholder: 'Wachtwoord (minimaal 8 tekens)',
      userPasswordConfirmLabel: 'Wachtwoord bevestigen',
      userPasswordConfirmPlaceHolder: 'Wachtwoord bevestigen',
      userAgreementCheckbox: 'Ik accepteer de overeenkomst volledig',
      userAgreementViewDoc: 'Bekijk overeenkomst',
      userMissingFieldsMessage: 'Sommige velden ontbreken, vul de verplichte velden in.',
      userMinLengthMessage: 'De gebruikersnaam moet minimaal 8 tekens lang zijn.',
      userEmailValidMessage: 'Gelieve een geldig e-mailadres in te vullen.',
      userPasswordValidationsMessage: 'Wachtwoord is niet sterk genoeg. Moet minimaal 8 tekens bevatten <br/> Moet minimaal 1 kleine letter bevatten.<br/>Moet minimaal 1 hoofdletter bevatten<br/>Moet één cijfer bevatten.<br/>Het moet ten minste één van deze tekens bevatten: @$!%*?&',
      userPasswordMismatchMessage: 'Wachtwoord komt niet overeen.',
      userAcceptAgreementMessage: 'U moet accepteren dat u onze overeenkomstvoorwaarden heeft gelezen en geaccepteerd.',
      userSuccesfullyCreatedMessage: 'Gebruiker is aangemaakt. Open alstublieft uw e-mailaccount en schakel deze in.',
    },
    edit:{
      title: 'Gebruikersaccounteditie',
      tabs: [
        'Gebruikers account',
        'Personal',
        'Locatie',
        'Auto gegevens',
      ],
      account: {
        avatarSelection: 'Avatar editie/selectie',
        avatarUploadImage: 'Afbeelding uploaden',
        avatarSave: 'Opslaan',
        uploadAvatarOkMessage: 'Avatar afbeelding succesvol bijgewerkt',
      },
      personal: {
        title: 'Persoonlijke gegevens',
        contactFirstName: 'Voornaam',
        contactLastName: 'Achternaam',
        contactGenderLabel: 'Geslacht',
        contactGenderMale: 'Man',
        contactGenderFemale: 'Vrouw',
        contactBirthDateLabel: 'Geboorte datum',
        contactNotAdultMessage: 'De leeftijd van de bestuurder is niet die van een wettelijke volwassene',
        contactSave: 'Informatie opslaan',
        viewLicense: 'Rijbewijs bekijken',
        licenseSave: 'Rijbewijs opslaan',
        uploadLicenseLabel: 'Upload uw rijbewijs (voor-en achterkant)',
        updateContactOkMessage: 'Contactgegevens bijgewerkt',
        uploadLicenseRequiredLengthFilesMessage: 'Selecteer voor- en achterkantafbeeldingen voor uw rijbewijs',
        uploadLicenseFileOkMessage: 'Rijbewijs bijgewerkt',
      },
      location: {
        title: 'Adres',
        streetLabel: 'Straat',
        streetPlaceHolder: 'Straat naam',
        externalNumber: 'Extern nummer',
        externalNumberPlaceHolder: 'Adres extern nummer',
        district: 'Plaats',
        districtPlaceHolder: 'Plaats',
        zipCode: 'Postcode',
        zipCodePlaceHolder: 'Postcode',
        phone: 'Telefoonnummer',
        phonePlaceHolder: 'Voeg uw telefoonnummer toe',
        email: 'E-mail',
        emailPlaceHolder: 'Voeg uw e-mailadres toe',
        locality: 'Land',
        localityPlaceHolder: 'Land',
        addressSave: 'Adres opslaan',
        updatedContactAdressMessage: 'Contactadresgegevens bijgewerkt',
      },
    },
  },
  home: {
    title: 'Home',
    panelLeft: {
      userLabel: 'Gebruiker',
      userNewAccountLabel: 'Nieuw account',
      userResetPasswordLabel: 'Wachtwoord opnieuw instellen',
      userEditAccountLabel: 'Bewerk account',
      item: 'Leningen (testen)',
    },
    panelRight: {
      userLabel: 'Gebruiker',
      userRegister: 'Registreer',
      sessionLabel: 'Sessie',
      sessionLogInLabel: 'Inloggen',
      sessionLogOutLabel: 'Uitloggen',
      lightThemeLabel: 'Licht thema',
      selectLanguageLabel: 'Taal',
      sessionLogOutMessage: 'Je bent uitgelogd.',
      allowWebNotifications: 'Notificaties (web)',
      notAllowedWebNotificationsMessage: 'Als je meldingen niet toestaat, kun je geen berichten ontvangen wanneer je een aanvraag voor je auto krijgt of wanneer iemand erom vraagt.',
    },
    topMenu: {
      offer: 'Aanbod',
      lend: 'Lener',
      howItWorks: 'Hoe werkt het?',
      contact: 'Contact',
      notifications: 'Notificaties',
    },
    main: {
      borrowCarLabel: 'Leen een Auto',
      sloganLabel: 'Samen maken we de wereld duurzamer.',
      whatCarsLabel: 'Welke auto\'s zijn beschikbaar?',
      location: 'Locatie',
      date: 'Datum',
      viewAvailabilityLabel: 'Beschikbaarheid bekijken',
      flexible: 'Flexibel',
      durable: 'Duurzaam',
      transparent: 'Transparant',
      loanOfferLabel: 'Leen aanbod'
    },
  },
  car: {
    dashboard: {
      title: 'Dashboard',
      carList: 'Auto lijst',
      additionalData: 'Extra gegevens',
      aboutLender: 'Over de uitlener'
    },
    features: {
      titleShort: 'Kenmerken',
      titleDialog: 'Selecteer Autokenmerken',
      isUsed: 'Gebruikt?',
      name: 'Naam',
      value: 'Waarde',
    },
    edit: {
      title: 'Autodata-editie',
      useMyCarLabel: 'Ik wil mijn auto gebruiken',
      manufacturerLabel: 'Fabrikant',
      modelLabel: 'Model',
      yearLabel: 'Jaar',
      priceLabel: 'Prijs',
      carIsEnsured: 'Mijn auto is all-risk verzekerd.',
      descriptionLabel: 'Omschrijving',
      carSave: 'Gegevens opslaan',
      carImageUploadLabel: 'Auto afbeelding',
      carImageUploadSave: 'Auto afbeelding',
      carPlateUploadLabel: 'Kenteken afbeelding',
      carPlateUploadSave: 'Kenteken afbeelding',
      carFeaturePopupLabel: 'Auto kenmerken',
      carImagesPhotoBrowserLabel: 'Auto afbeeldingen',
      carImageFileListLabel: 'Lijst opslaan',
      uploadCarImagesLabel: 'Upload afbeeldingen van uw auto',
      updatedCarMessage: 'Autogegevens succesvol bijgewerkt',
      selectFileToUpload: 'Selecteer een bestand om te uploaden',
      carImageUploadedOkMessage: 'Autoafbeelding succesvol geüpload',
      carPlateUploadedOkMessage:'Autoafbeelding succesvol geüpload',
      uploadCarImagesLimitMessage: 'U moet 6 afbeeldingen voor uw auto selecteren',
      carImageListUploadedOkMessage: 'Lijst met auto-images succesvol geüpload',
      carIsUsedStatusMessage: 'De auto is momenteel uitgeleend en kan nu niet worden bijgewerkt.',
      carSize: 'Autogrootte',
      checkSizeMessage: 'Selecteer een autogrootte om de voorgestelde prijs te bepalen',
      recommendedPriceMessage: 'De prijs moet tussen %% en %% liggen, huidige prijs: %%',
      freeKilometers: "Vrije Kilometers",
    },
  },
  loan: {
    title: 'Leen',
    lends: 'Uitleners',
    borrows: 'Leners',
    tabs: [
      'Aanvraag',
      'Beslissen',
      'Betaling',
      'Verzekering',
      'Afspraak',
      'Ontmoeting',
      'Stoppen',
      'Terugkerend',
      'Beoordeling'
    ],
    dashboard: {
      carLoanTag: 'Label',
      lender: 'Uitlener',
      borrower: 'Lener',
      startDate: 'Startdatum',
      endDate: 'Einddatum',
      daysToBorrow: 'Dagen te leen',
      totalToPay: 'Totaal te Betalen',
      discounts: 'Kortingen',
      isGoingAbroad: 'Gaat Naar het Buitenland',
      returningKilometers: 'Terugkomende km\'s',
      currentKilometers: 'Huidige km\'s',
      pricePerDay: 'Prijs per Dag',
    },
    request: {
      loanPeriod: 'Leen periode',
      loanRangeDatePlaceHolder: 'Selecteer een datumbereik',
      onlyDriver: 'Ik ben de enige bestuurder',
      driveAbroad: 'Ga je naar het buitenland rijden? (+€ 1 per dag)',
      extraDriverData: 'Extra chauffeursgegevens',
      messageRangeDate: 'Selecteer een reeks data waarop u reist',
      onEmptyFormField: 'Vul alstublieft de gegevens van de extra bestuurder in, veld: %%',
      confirmSaveRequest: 'Voeg uw wachtwoord toe om te bevestigen dat u akkoord gaat met het vragen naar deze auto',
      messageSavedRequest: 'De uitlener moet uw verzoek accepteren',
      onLoanPeriodAtLeast3Days: 'De leenperiode moet minimaal 3 dagen vanaf vandaag zijn ingesteld',
      onLoadPeriodGreater30Days: 'De leenperiode mag niet langer zijn dan 30 dagen',
    },
    deciding: {
      confirmCancelSaveDeciding: 'Wilt u ANNULEREN om deze auto uit te lenen?',
      messageCancelDeciding: 'Lener heeft geweigerd de auto te lenen. Bewerkingen annuleren ...',
      confirmAcceptSaveDeciding: 'Voeg uw wachtwoord toe om te bevestigen dat u akkoord gaat met het lenen van uw auto',
      messageAcceptlDeciding: 'Voor een verzekering moet u een aanbetaling doen',
    },
    statusLabel: {
      1: 'Aangevraagd',
      2: 'Geaccepteerd',
      3: 'Betaald',
      4: 'Volgende',
      12: 'Geannuleerd door Verhuurder',
      13: 'Geannuleerd door Huurder',
    },
  },
  admin:{
    title: 'Beheerderspaneel',
    sections:{
      carPricing: 'Slimme prijzen',
      carPricingEdit: 'Prijs Editie',
    },
  }
};

export default dictionaryNL;
